import React from "react";
import { FaFacebook } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import "./style.scss";
import "./columns.scss";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            facebook
          }
        }
      }
    `}
    render={data => (
      <footer className="footer center">
        <div className="content has-text-centered">
          <p className="is-size-7 has-text-white">
            Autohemoterapia en México | Hemovacuna®. Todos los derechos
            reservados.
          </p>
          <p className="is-size-7 has-text-white">
            Calle Ote. 245 #6, Colonia, Agrícola Oriental, Iztacalco, 08500
            Ciudad de México, CDMX <br />
            Tel. 55 3999 4763
          </p>

          <p className="is-size-7 has-text-white">
            <a
              href="https://equilibrio.studio/posicionamiento-web-queretaro"
              className="has-text-white"
            >
              Posicionamiento Web
            </a>{" "}
            hecho por{" "}
            <a href="https://equilibrio.studio" className="has-text-white">
              Equilibrio.Studio
            </a>
            .
          </p>

          <article className="media center">
            <span className="icon">
              <a href={data.site.siteMetadata.facebook}>
                <FaFacebook size="fa-2x" color="white" />
              </a>
            </span>
          </article>
        </div>
      </footer>
    )}
  />
);

export default Footer;
